const Feature = () => {
    return (
        <div className="feature_container">
            <p className="feature_headline">
                How will AI shape the future?
            </p>
            <div className="cards_container">
                <div className="card_container">
                    <p className="card_headline">Face Recognition</p>
                    <img src="/img/face.png" alt="face recognition" className="card" />
                </div>
                <div className="card_container">
                    <p className="card_headline">AI Chip</p>
                    <img src="/img/chip.jpg" alt=" AI chip" className="card" />
                </div>
                <div className="card_container">
                    <p className="card_headline">Self Driving</p>
                    <img src="/img/driving.jpg" alt="self driving" className="card" />
                </div>
                <div className="card_container">
                    <p className="card_headline">Education</p>
                    <img src="/img/edu.jpg" alt="AI education" className="card" />
                </div>

            </div>
        </div>
    )
}
export default Feature;