
const Footer = () => {
    return (
        <div className="footer_container">
            <p>Designed by Japanese.org</p>
            <div className="footer_contact">
                <p className="">Contact us:</p>
                <a href='https://twitter.com/JapaneseORG' target='_blank' rel='noreferrer' className="footer_contact">
                    <img src='/img/twitter.jpg' alt='twitter' className="footer_img"></img>
                    @JapaneseORG</a>
            </div>
        </div>
    );
}

export default Footer;